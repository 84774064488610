// TODO: to load component asynchonous (from extern) - but for this we need to separate the
// "loading logic" and the component rendering
import { createApp } from 'vue'
// need window.i18n instance which is installed here
import i18n from '@gd/i18n'
import App from './App.vue'
import { Storage, STORE_NAME } from '@gd/storage'
import GDModal, { loadModalComponents } from '@gd/modal'
import { loadTComponents } from '@gd/t-components'
import '@gd/modal/dist/gd-modal.css'
// maybe CSS will be (later) provide by "main-web instance like classic-web"
import '@gd/t-components/dist/t-components.css'
import '@gd/common-styles/dist/fonts/dSari-loadFonts.less'
import '@gd/common-styles/dist/icf-fonts.less'
import { getCookieProperty } from './lib/cookie'
import '@/styles/index.less'

if (!localStorage.gd) {
  await fetch(`/gd/rest/config/gdStorage`, {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    cache: 'no-cache',
  })
    .then((res) => res.json())
    .then((d: GD_store.GD) => new Storage().write(STORE_NAME.GD, d))
}

const storage = new Storage()
const decisions = storage.read(STORE_NAME.GD).decisions

if (decisions.indexOf('fe-cookie-consent') !== -1) {
  const cookieConsent = getCookieProperty(document.cookie, 'cookieConsent')
  const storageConsent = storage.read(STORE_NAME.GD).cookieConsent

  storage.write(STORE_NAME.GD, {
    cookieConsent: {
      ...storageConsent,
      ...cookieConsent,
    },
  })

  const app = createApp(App).use(i18n)
  loadTComponents(app)
  loadModalComponents(app)
  app.component('gd-modal', GDModal)
  app.mount(document.body.appendChild(document.createElement('div')))
}
