const getCookieProperty = function (cookie: string, propName = '') {
  const decodedCookieStr = cookie ? decodeURIComponent(cookie) : ''
  const cookieProps = decodedCookieStr.split('; ')
  const property = cookieProps.find((c) => c.startsWith(propName))
  const propDataStr = property?.split('=')[1] as string

  if (propDataStr) {
    return JSON.parse(propDataStr)
  } else {
    return null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const writeCookieProperty = function (propName: string, data: any) {
  document.cookie = `${propName}=${encodeURIComponent(JSON.stringify(data))}`
}

export { getCookieProperty, writeCookieProperty }
