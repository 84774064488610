import { Storage, STORE_NAME } from '@gd/storage'
import { jslog } from '@gd/jslog'
import { writeCookieProperty } from './cookie'

/**
 * removes everything from localStorage which is not in the cookieConsent.names -list
 * @param cookieConsent
 */
function clearLocalStorage(cookieConsent: GD_cookieConsent.CookieConsent) {
  Object.keys(localStorage).forEach((name) => {
    if (cookieConsent.names.indexOf(name) === -1) {
      delete localStorage[name]
    }
  })
}

export const postSettings = function (data: GD_cookieConsent.CookieConsentPost) {
  const storage = new Storage()
  const currentSettings = storage.read(STORE_NAME.GD).cookieConsent
  return fetch('/gd/rest/cookieConsent', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
    cache: 'no-cache',
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json()
      } else {
        throw {
          errorCode: 'cookie-consent-failed',
          message: `POST to /gd/rest/cookieConsent failed with HTTP status ${res.status}`,
        }
      }
    })
    .then((data: GD_cookieConsent.CookieConsent) => {
      storage.write(STORE_NAME.GD, {
        cookieConsent: {
          ...currentSettings,
          ...data,
        },
      })
      clearLocalStorage(data)
    })
    .catch((err) => {
      // Store fallback locally
      const currentSettings = storage.read(STORE_NAME.GD).cookieConsent
      const cookieConsent = {
        ...currentSettings,
        ...data,
        consented: true,
      }
      storage.write(STORE_NAME.GD, { cookieConsent })
      writeCookieProperty('cookieConsent', cookieConsent)
      jslog.send({
        error: {
          name: err.errorCode || err.name,
          message: err.message,
        },
      })
      // eslint-disable-next-line
      console.error('Unable to persist cookie settings.')
      throw err
    })
}
