import { ACTION, track as trackOrig, TrackingConfig } from '@gd/tracking'

interface TrackOptions {
  id?: string
  elem?: string
  action?: ACTION
  data?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface TrackOptionsOrig extends TrackOptions {
  key: string
}

const track = (conf: TrackOptions, options?: TrackingConfig) => {
  conf.key = 'consent-web'
  const opt = { ...options, stealthy: true }
  return trackOrig(conf as TrackOptionsOrig, opt)
}
export { track }
